import React, { useState } from 'react';
import './PostInventario.scss';
import { IoIosWarning } from 'react-icons/io';
import { FcAddImage } from 'react-icons/fc';
import toast from "react-hot-toast";
import { usePosts } from "../../context/postContext";
import { BsFillTrashFill } from 'react-icons/bs';
import { FaArrowUp, FaArrowDown, FaCheck } from "react-icons/fa";
import { LuTextCursorInput } from "react-icons/lu";
import { MdCancel } from "react-icons/md";

export function PostInventario({ post, onEdit, selectedColor }) {
  const { deletePost, updatePost } = usePosts();
  const [isEditing, setIsEditing] = useState(false);

  // Estados para cada campo editable:
  const [editedTitle, setEditedTitle] = useState(post.title);
  const [editedPrecio, setEditedPrecio] = useState(post.precio);
  const [editedCantidad, setEditedCantidad] = useState(post.cantidad);
  const [editedPrecioCompra, setEditedPrecioCompra] = useState(
    post.precioCompra
  );
  const [editedDescription, setEditedDescription] = useState(post.description);
  const [editedSede, setEditedSede] = useState(post.sede || "");
  // Nuevo estado para "categoria"
  const [editedCategoria, setEditedCategoria] = useState(post.categoria || "");

  const showCuidadoIcon = post.cantidad <= 3;

  const formatPrecio = (precios) => {
    return precios.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const ganancia = post.precio - post.precioCompra;
  const gananciaIcono =
    ganancia > 0 ? (
      <FaArrowUp className="icon-ganancia" />
    ) : (
      <FaArrowDown className="icon-perdida" />
    );

  const handleDelete = (id) => {
    toast(
      (t) => (
        <div>
          <p className="text-white">
            Seguro quiere eliminar <strong>{post.title}</strong>?
          </p>
          <div>
            <button
              className="bg-red-500 hover:bg-red-400 px-3 py-2 text-sm text-white rounded-sm mx-2"
              onClick={(e) => {
                deletePost(id);
                toast.dismiss(t.id);
              }}
            >
              Eliminar
            </button>
            <button
              className="bg-slate-400 hover:bg-slate-500 px-3 py-2 text-white rounded-sm mx-2"
              onClick={() => toast.dismiss(t.id)}
            >
              Cancelar
            </button>
          </div>
        </div>
      ),
      {
        duration: "4000",
        style: {
          background: "#202020",
        },
      }
    );
  };

  const handleSave = async () => {
    try {
      await updatePost(post._id, {
        title: editedTitle,
        precio: editedPrecio,
        cantidad: editedCantidad,
        precioCompra: editedPrecioCompra,
        description: editedDescription,
        sede: editedSede,
        categoria: editedCategoria, // <-- Agregamos la categoría aquí
      });
      setIsEditing(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {isEditing && (
        <div className="overlay" onClick={() => setIsEditing(false)}></div>
      )}
      <div
        className={`container-producto-inventario ${
          isEditing ? "editing" : ""
        }`}
        onClick={() => onEdit(post)}
        style={{ borderColor: selectedColor }}
      >
        {showCuidadoIcon && <IoIosWarning className="warning-icon" />}
        <div className="InfoContainer flex">
          <div className="image-container">
            {post.image ? (
              <img
                className="image-inventario"
                src={post.image.url}
                alt={post.title}
              />
            ) : (
              <FcAddImage className="default-imagee" alt="default" />
            )}
          </div>
          <hr className="linea" style={{ backgroundColor: selectedColor }} />

          {/* Título */}
          {isEditing ? (
            <input
              type="text"
              value={editedTitle}
              onChange={(e) => setEditedTitle(e.target.value)}
              autoFocus
              className="format-titulo"
            />
          ) : (
            <p className="info-main">{post.title}</p>
          )}

          {/* Precio */}
          <div className="capsula-precio">
            {isEditing ? (
              <input
                type="text"
                value={editedPrecio}
                onChange={(e) => setEditedPrecio(e.target.value)}
                className="format-precio"
              />
            ) : (
              <h3 className="Precio">{formatPrecio(post.precio)}</h3>
            )}
          </div>

          {/* Sede */}
          <div>
            {isEditing ? (
              <input
                type="text"
                value={editedSede}
                onChange={(e) => setEditedSede(e.target.value)}
                className="format-sede"
              />
            ) : (
              <h3 className="Sede">{post.sede}</h3>
            )}
          </div>

          {/* NUEVO: Categoría */}
          <div>
            {isEditing ? (
              <input
                type="text"
                value={editedCategoria}
                onChange={(e) => setEditedCategoria(e.target.value)}
                className="format-categoria"
                placeholder="Categoría"
              />
            ) : (
              <h3 className="Categoria">{post.categoria}</h3>
            )}
          </div>

          <div className="informacion-adicional">
            <div className="capsula-precio">
              <div className="ganancia">
                {gananciaIcono}
                <p className="info-main">${formatPrecio(ganancia)}</p>
              </div>
            </div>
            <div className="capsula-cantidad">
              {isEditing ? (
                <input
                  type="text"
                  value={editedCantidad}
                  onChange={(e) => setEditedCantidad(e.target.value)}
                  className="format-cantidad"
                />
              ) : (
                <span className="info-main cantidad-numero">
                  {formatPrecio(post.cantidad)}
                </span>
              )}
              <p className="info-second">Cantidad</p>
            </div>
          </div>

          <div className="capsula-ganancia">
            <div className="precios-productos">
              {isEditing ? (
                <input
                  type="text"
                  value={editedPrecioCompra}
                  onChange={(e) => setEditedPrecioCompra(e.target.value)}
                  className="format-compra"
                />
              ) : (
                <p className="info-main">C ${post.precioCompra}</p>
              )}
              <hr className="hr-post" />
              <p className="info-main">V ${formatPrecio(post.precio)}</p>
            </div>
          </div>

          {/* Descripción */}
          {isEditing ? (
            <input
              type="text"
              value={editedDescription}
              onChange={(e) => setEditedDescription(e.target.value)}
              className="format-mensaje"
            />
          ) : (
            <p className="info-second">{post.description}</p>
          )}

          <div className="flex capsula-ediciones">
            <div className="capsula-editar">
              {!isEditing && (
                <button
                  className="edit-button"
                  onClick={() => setIsEditing(true)}
                >
                  <LuTextCursorInput className="DeleteIcon" />
                </button>
              )}
              {isEditing && (
                <div className="dos-botones">
                  <button onClick={handleSave}>
                    <FaCheck className="check" />
                  </button>
                  <button onClick={() => setIsEditing(false)}>
                    <MdCancel className="cancel" />
                  </button>
                </div>
              )}
            </div>

            <div className="capsula-delete">
              <button
                className="delete-button justify-center items-center"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete(post._id);
                }}
              >
                <BsFillTrashFill className="DeleteIcon" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
